import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/year-select.module.scss"

export default function YearSelect({ years, currentYear, setCurrentYear }) {
  const handleChange = event => setCurrentYear(parseInt(event.target.value))

  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor="years">
        Browse results archive
      </label>

      <select
        className={styles.select}
        name="years"
        id="years"
        defaultValue={currentYear}
        onBlur={handleChange}
        onChange={handleChange}
      >
        {years.map((year, index) => (
          <option key={index} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  )
}

YearSelect.propTypes = {
  /**
   * List of years to use in the Select
   */
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * The current year
   */
  currentYear: PropTypes.number.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
}
