import { useStaticQuery, graphql } from "gatsby"

export const useArchive = () => {
  const { allWpYear } = useStaticQuery(graphql`
    query {
      allWpYear {
        nodes {
          archiveTemplate {
            year
            flexibleContent {
              __typename
              ... on WpYear_Archivetemplate_FlexibleContent_Copy {
                copy
              }
              ... on WpYear_Archivetemplate_FlexibleContent_Downloads {
                downloads {
                  name
                  file {
                    localFile {
                      extension
                      publicURL
                      url
                      size
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return allWpYear.nodes.map(element => ({
    year: element.archiveTemplate.year,
    content: element.archiveTemplate.flexibleContent,
  }))
}
