import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useArchive } from "../../hooks/use-archive"
import { connect } from "react-redux"
import { updatePageURI } from "./../../redux/actions"

import Layout from "../layouts/base"
import SEO from "../atoms/seo"
import Hero from "../molecules/hero"
import Archive from "../organisms/archive"

// Redux
const mapDispatchToProps = {
  updatePageURIAction: updatePageURI,
}

function Page({ updatePageURIAction, data }) {
  const page = data.wpPage
  const archive = useArchive()

  useEffect(() => {
    updatePageURIAction(page.uri)
  })

  return (
    <Layout>
      <SEO
        description={page.seo.metaDesc}
        robots={page.seo.metaRobotsNoindex}
        slug={page.uri}
        title={page.seo.title}
      />
      <Hero
        title={page.hero.title || page.title}
        lead={page.hero.lead}
        pageId={page.id}
      />
      <Archive archive={archive} />
    </Layout>
  )
}

export default connect(null, mapDispatchToProps)(Page)

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      seo {
        title
        metaDesc
        metaRobotsNoindex
      }
      hero {
        title
        lead
      }
    }
  }
`
