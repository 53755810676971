import React, { useState } from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/archive.module.scss"

import YearSelect from "./../molecules/year-select"
import FlexibleContent from "../organisms/flexible-content"

function Archive({ archive }) {
  const yearsList = archive.map(item => item.year).sort((a, b) => b - a)
  const [currentYear, setCurrentYear] = useState(yearsList[0])

  return (
    <div className={styles.container}>
      <YearSelect
        years={yearsList}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />
      <h2>Staff Survey {currentYear}</h2>
      <FlexibleContent
        elements={archive.find(item => item.year === currentYear).content}
      />
    </div>
  )
}

export default Archive

Archive.propTypes = {
  /**
   * Array of flexible content elements
   */
  archive: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired
  ),
}
